import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import SolutionNavBar from './NavBar';
import Feature from './Feature';
import { FeatureList } from '../../config/features.config';

const Features: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar />
        <section className="subcontainer">
            <h1 className="title">Features of RegNxt</h1>
            {FeatureList.map((feat, idx) => <Feature key={idx} {...feat} />)}          
    </section>
  </div>
);

export default Features;
