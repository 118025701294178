import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import SolutionNavBar from './NavBar';
import RNArchitecture from '../../assets/img/architecture.png';

const Architecture: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar />
        <section className="subcontainer">
            <h1 className="title">Architecture of Regnxt</h1>

            RegNxt is built on a layered architecture, making use of an <b>API</b>.
            <br /> Users can access all functionalities in 2 ways:
            <div className="li-theme1">
            <ul>
                    <li>through a <b>User Interface</b>: users benefit from the intuitive workflow and centralised features in a single <b>front-end</b>,</li>
                    <li>through the <b>Controllers</b>: users whish to benefit from RegNxt's features, but from within an existing platform.
                        This so-called <b>headless archtecture</b> allows triggering on-demand or automated requests, enabling a smooth integration.</li>
            </ul>
           </div>
            <br /> The API consists of multiple controllers, each serving a different purpose.
            Within the controllers, a <b>security component</b> checks if permissions allow the execution of the request.
            <br />Granted requests and forwarded to the <b>back-end</b> (.Net C#), where all the <b>business logic</b> is executed and - where needed - 
            (meta)data is returned from the persistence layer (aka database).
            <br/> 


            <img className="rn-image"
                src={RNArchitecture}
                alt="RegNxt Architecture"
                aria-hidden
            />

    </section>
  </div>
);

export default Architecture;
