import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import SolutionNavBar from './NavBar';
import { getRouteMetaInfo } from '../../config/routes.config';

const Solution: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar/>
        <section className="subcontainer">
            <h1 className="title">Solution overview</h1>
            RegNxt is a complete solution, a single location where all tasks in the complete reporting journey are organized logically to improve the user experience.
            <br/>
                     
            <div className="li-theme1">
            <ul>
               <li>A software solution for regulatory reporting</li>
               <li>Flexible way of integrating with source data. Easy monitoring of data flows</li>
                    <li>Multiple ways of improving/guaranteeing data quality (validation rules)</li>                    
                    <li>Data lineage through drill through functionality (allowing reversing the data flow)</li>
                    <li>Functional assitance through cell-level ITS references</li>
                    <li>A modern architecture catering from on-premise and cloud deployment</li>
                    <li>Packed with features to encourage collaboration & documentation</li>
                    <li>Functional assitance through cell-level ITS references</li>
                </ul>
            </div>
    </section>
  </div>
);

export default Solution;
