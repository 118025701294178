import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import SolutionNavBar from './NavBar';
import Ecosystem from '../../assets/img/eco_system.png';

const Component: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar />
        <section className="subcontainer">
            <h1 className="title">Regnxt's eco system</h1>
            
            <h2 className="subtitle">High-level view on components</h2>
            <img className="rn-image"
                src={Ecosystem}
                alt="RegNxt Workflow"
                aria-hidden
            />
            <br />
            <h2 className="subtitle">Core-components</h2>
            Within RegNxt, components are organized to serve a common service.
            Include Swagger image.
            <br /> RD delivers meta-data
            <br /> RI delivers report data
            <br /> RQ delivers data quality services
            <br /> RT delivers submission services
           
    </section>
  </div>
);

export default Component;
