import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import SolutionNavBar from './NavBar';

const Roadmap: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar />
        <section className="subcontainer">
            <h1 className="title">The roadmap: what to expect in the (near) future</h1>
            We are working hard to add following features:
            <div className="li-theme1">
            <ul>
                <li>Fully automated report population through a generic business rules engine that transforms source data</li>
                    <li>2 way data lineage: clear overview of source data and how it ends up in a report cell, and vice-versa</li>
                    <li>Variance analysis: comparing report with data from previous reporting periods</li>
                    <li>Reconciliation reports</li>
                    <li>User-oriented dashboard</li>
                </ul>
            </div>
    </section>
  </div>
);

export default Roadmap;
