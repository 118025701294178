import React from 'react';
import { NavLink } from 'react-router-dom';
import { MetaInfo } from '../../components';
import type { FunctionComponent } from 'react';
import { getRouteMetaInfo } from '../../config/routes.config';

const Home: FunctionComponent = () => (
  <div className="view-wrapper">
    <MetaInfo {...getRouteMetaInfo('Home')} />

        <section className="container">
            <div className="column">
                <h1 className="title has-text-centered">Who are we?</h1>
                <div className="content">We are a group of enthousiast professionals with a long track record in the financial industry.
                    We are committed to support financial institutions in their regulatory reporting obligations.
                    Our expertise lies mainly in EBA supervisory reports (COREP, FINREP, AE, LCR, NSFR ...), but we can also help with reports imposed by other regulators
                    (EIOPA, NBB, DNB, ECB, OECD).
                    <br />Our next generation regulatory reporting solution offers an refreshing view on reporting and aims at decomplexifying
                    the complete workflow from source data to submission to the regulator.                    
                </div>
            </div>
            <hr/>
            <div className="container has-text-centered">
                <div className="title">What do we offer?</div>
            </div>

            <div className="columns">
                <div className="column offering-item">                    
                    <h2 className="offering-header">A software solution</h2>
                    <div className="content">
                        <div className="li-theme1">
                            <ul>
                                <li>focussed on regulatory reporting</li>
                                <li>mainly covering EBA(DPM) such as COREP, FINREP,AE,LCR,NSFR...</li>
                                <li>intuitive workflow enforced by UI</li>
                                <li>all-in-one platform</li>
                            </ul>
                            <NavLink
                                end
                                to={'/solution'}
                                key={'/solution'}                                
                            >
                                <button type="button" className="rn-button">
                                    Details
                                </button>
                            </NavLink>                           
                        </div>
                    </div>
                </div>
                <div className="column offering-item">
                    <h2 className="offering-header">A resource solution</h2>
                    <div className="content">
                        <div className="li-theme1">
                            <ul>
                                <li>Managed services</li>
                                <li>Consulting</li>
                                <li>Implementation of a fully integrated platform (source systems)</li>
                                <li>Functional support</li>
                            </ul>
                            <NavLink
                                end
                                to={'/services'}
                                key={'/services'}
                            >
                                <button type="button" className="rn-button">
                                    Details
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>         
    </section>
  </div>
);

export default Home;
