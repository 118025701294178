import React from 'react';
import { NavLink } from 'react-router-dom';
import type { FunctionComponent } from 'react';
import { routes } from '../config/routes.config';
import RegNxtLogo from '../assets/img/RegNxt.png';

const NavBar: FunctionComponent = () => (
  <nav
    role="navigation"
    className="navbar"
    aria-label="Main navigation"
  >
    <div className="navbar-wrapper">
      <div className="brand-wrapper">
       <img className="logo"
          src={RegNxtLogo}             
          alt="RegNxt"
          aria-hidden         
        />
      </div>
      <div className="navbar-routes">
       {routes.filter(function (el) {
               return el.context === 'main';
             }).map(({ path, name }) => (
          <NavLink
            end
            to={path}
            key={path}
            className={({ isActive }) => 'navbar-item' + (isActive ? ' is-active' : '')}
          >
            <span>{name}</span>
          </NavLink>
        ))}              
      </div>
    </div>
  </nav>
);

export default NavBar;
