export type Feature = Readonly<{
  description: string;
  package_name?: string;
}>;

export const FeatureList: Feature[] = [
  {
    description: 'Regulatory reporting'
  },
  {
    description: 'Focus on EBA (DPM) reports'
  },
  {
    description: 'Intuitive UI'    
  },
  {
    description: 'Processing and reporting in one centralised application'
  },
  {
    description: 'Packed with features to maximize collaboration and productivity'
  },
  {
     description: 'Layered storage model (allowing to incrementally update values)'
  }
];