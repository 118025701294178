import React, { useState, Fragment } from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import toast, { Toaster } from 'react-hot-toast';
import emailjs from "@emailjs/browser";

const ContactForm: FunctionComponent = () => {    
    const [subject, setSubject] = useState("");
    const [from_name, setFromName] = useState<string>('');
    const [user_email, setUserEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleSubjectChange = (event:any) => {
        // console.log(e.target.value);
        setSubject(event.target.value);
    };

    const handleNameChange = (event:any) => {
        setFromName(event.target.value);
    }

    const handleMailChange = (event: any) => {
        setUserEmail(event.target.value);
    }

    const handleMessageChange = (event: any) => {
        setMessage(event.target.value);
    }

    const validateForm = () => {
        if (from_name.length === 0) {
            toast.error("Please give your name.");
            return false;
        }

        if (!validateEmail(user_email)) {
            toast.error("Please provide a correct email address.");
            return false;
        }

        if (subject === "") {
            toast.error("Please choose a subject.");
            return false;
        }

        if (message.length === 0) {
            toast.error("Please type something in the message box.");
            return false;
        }

        return true;
    };

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const sendEmail = (event: any) => {
        event.preventDefault();

        var validData = validateForm();
        
        if (validData) {
            const formValues = {
                from_name: from_name,
                user_email: user_email,
                topic: subject,
                message: message
            };

            emailjs
                .send("RegNxt", "RegNxt_Contact", formValues, "2D3pStWSXs1otPr6t")
                .then(
                    (result) => {
                        toast.success("Your message was sent successfully.");
                    },
                    (error) => {
                        toast.error("There was an isue sending your message!");
                    }
                );
                
            //reset values
            setSubject('');
            setFromName('');
            setUserEmail('');
            setMessage('');
        }
    };

    return (
        <div className="container view-wrapper">
            <MetaInfo {...getRouteMetaInfo('Contact')} />
            <h1 className="title">Contact</h1>

            <section className="contact-section">              
                <form className="form" onSubmit={sendEmail}>
                    <div className="id">
                        <div className="name">
                            <label htmlFor="name">Name</label>
                            <input type="text"
                                className="form-control"
                                name="from_name"
                                value={from_name}
                                onChange={handleNameChange} />
                        </div>
                        <div className="email">
                            <label htmlFor="email">Email</label>
                            <input type="email"
                                className="form-control"
                                name="user_email"
                                value={user_email}
                                onChange={handleMailChange} />
                        </div>
                    </div>
                    <div className="subject">
                        <label htmlFor="subject">Subject</label>

                        <input
                            id="subject" type="hidden"
                            className="form-control"
                            name="subject"
                            value={subject}

                        />
                        <select
                            className="form-select form-select-lg mb-3"
                            name="subjectselect"
                            aria-label=".form-select-lg example"
                            defaultValue={"Choose a subject..."}
                            onChange={handleSubjectChange}
                        >
                            <option value="I have a job opportunity/project proposal">
                                I have a job opportunity/project proposal
                            </option>
                            <option value="I have a question on one of your projects">
                                I would like to setup a meeting to discuss the possibilities
                            </option>
                            <option value="I have another question">
                                I have another question
                            </option>
                        </select>
                    </div>
                    <div className="content">
                        <label id="contect" htmlFor="content">Content</label>
                        <textarea name="content" id="" cols={30} rows={10}
                            value={message}
                            onChange={handleMessageChange} />
                    </div>
                    <button type="submit">SEND</button>                    
                </form>
            </section>


</div>

    );
};

const GenerateContactPage: FunctionComponent = () => {
    return (
        <div className="mb-3">
          
            <Fragment>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        duration: 5000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        }
                    }} />
               
                        <ContactForm />
                <br />

            </Fragment>
        </div>)
}


const Contact: FunctionComponent = () => {
    return (<GenerateContactPage/>);
}

export default Contact;
