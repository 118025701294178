import React from 'react';
import type { FunctionComponent } from 'react';
import { MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';
import SolutionNavBar from './NavBar';
import RNWorkflow from '../../assets/img/workflow.png';
import RNWorkflowScenario from '../../assets/img/workflow_scenario.png';

const DataFlow: FunctionComponent = () => (
  <div className="view-wrapper">
        <MetaInfo {...getRouteMetaInfo('About')} />
        <SolutionNavBar />
        <section className="subcontainer">
            <h1 className="title">The data flows within RegNxt</h1>
            <br />
            <h2 className="subtitle">Manual vs automated data flows</h2>
            When it comes to populating the reports, users can feed the cells through <b>manual</b> input or copy-past operations from external sources.
            (see blue arrows).
            <br />
            Of course, <b>automated</b> data flows are the preferred solution. Not only are programmatic data loads less error-prone, they also offer <b>immediate or scheduled triggering</b> (allowing outside business hours loads).
            <br /><br />For some existing vendor platforms, we offer <b>out-of-the-box plugins</b>, which do not need tailor made ETL (see green arrows).
            <br/>For those cases,we have already prepared the necessary processes to transform the vendors data model
            into RegNxt's optimized data model.
            <br /><br />
            When data is transferred diretly from the source data, client specific ETL processes are to be developped (see purple arrows).
            <br /><br />
            The <b>ideal combination</b>, is having <b>automated data flows, with manual adjustments</b> (when corrections are needed).
            <br />
            <img className="rn-image"
                src={RNWorkflow}
                alt="RegNxt Workflow"
                aria-hidden
            />
            <br /><br /><br />

            <h2 className="subtitle">Minimal vs full level automated data flows</h2>
            In belows diagram, the different options are shown on how to integrate with the source data.
            <br />
            We offer 3 different levels of integration, each with their pros and cons:
            <br /><br />
            <div className="columns">
                <div className="column offering-item">
                    <h2 className="offering-header min-df">Minimal integration</h2>
                    <div className="content">
                        <div className="li-theme1">
                            <ul>
                                <li className="li-pro">Source data is captured in dedicated silo's</li>
                                <li className="li-pro">Easy to understand mapping between source data and datasets</li>
                                <li className="li-con">Less control over data quality</li>
                                <li className="li-con">Limited data lineage capabilities</li>
                            </ul>                            
                        </div>
                    </div>
                </div>
                <div className="column offering-item">
                    <h2 className="offering-header hyb-df">Hybrid integration</h2>
                    <div className="content">
                        <div className="li-theme1">
                            <ul>
                                <li className="li-pro">Taking advantage of granular source data</li>                                
                                <li className="li-pro">All data is captured in generic (mult-purpose) data foundation layer</li>
                                <li className="li-con">Intermediate complexity turning into  datasets</li>
                                <li className="">Limited data lineage capabilities</li>
                            </ul>                           
                        </div>
                    </div>
                </div>
                <div className="column offering-item">
                    <h2 className="offering-header full-df">Full integration</h2>
                    <div className="content">
                        <div className="li-theme1">
                            <ul>
                                <li className="li-pro">Ful granularity of data</li>
                                <li className="li-pro">Full control over data quality (could even serve to improve data quality in source systems) </li>
                                <li className="li-pro">Full data lineage coverage (drill through between all layers)</li>                                
                                <li className="li-con">Complex implementation</li>
                                <li className="li-con">Heavy processing to move through the various layers</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column offering-item">
                    <h2 className="offering-header common-df">Common data flow</h2>
                    <div className="content">
                        <div className="li-theme1">
                            The dataset serves as central data feed for the workbooks, whatever scenario is put in place.
                            <br />
                            From there on, a standardized process can push the data into the workbooks.
                            <br /><br />
                            Standard functionality covers both directions between workbook and report.
                            <br />
                            The workbook values can be transmitted into the submission format to generate a report, and generated submissions files
                            can be imported back into a workbook.
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <img className="rn-image"
                src={RNWorkflowScenario}
                alt="RegNxt Workflow scenario"
                aria-hidden
            />           
         
    </section>
  </div>
);

export default DataFlow;
