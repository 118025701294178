import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isLocationValidRoute } from '../config/routes.config';

// Initialize the react-ga plugin using your issued GA tracker code + options
ReactGA.initialize([
    {
        trackingId: "G-9J410PK8DX",
        gaOptions: {
            cookieFlags: 'max-age=7200;secure;samesite=none'
        }
    }
]);

// Define custom hook to handle page tracking
const usePageTracker = (): void => {
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;

    if (isLocationValidRoute(pathname)) {
      const page = pathname + search;
      ReactGA.set({ page });
    }
  }, [location]);
};

export default usePageTracker;
