import React from 'react';
import { NavLink } from 'react-router-dom';
import type { FunctionComponent } from 'react';
import { routes } from '../../config/routes.config';
import './solnavbar.scss';

const SolutionNavBar: FunctionComponent = () => (
  <div >        
        <nav
            role="navigation"
            className="sol-navbar"
            aria-label="Main navigation"
        >
            <div className="sol-navbar-wrapper">
                <div className="sol-navbar-routes">
                    <NavLink
                        end
                        to={'/solution'}
                        key={'/solution'}
                        className={({ isActive }) => 'sol-navbar-item' + (isActive ? ' is-active' : '')}
                    >
                        <span>Overview</span>
                    </NavLink>
                    {routes.filter(function (el) {
                        return el.context === 'subsol';
                    }).map(({ path, name }) => (
                        <NavLink
                            end
                            to={path}
                            key={path}
                            className={({ isActive }) => 'sol-navbar-item' + (isActive ? ' is-active' : '')}
                        >
                            <span>{name}</span>
                        </NavLink>
                    ))}                 
                </div>
            </div>
        </nav>
  </div>
);

export default SolutionNavBar;
