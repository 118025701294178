import React from 'react';
import type { FunctionComponent } from 'react';
import { APP_NAME } from '../config/env.config';

const Footer: FunctionComponent = () => (
  <footer className="footer">  
    <div className="content has-text-centered">
      {`Copyright © ${new Date().getFullYear()} ${APP_NAME}`}
    </div>
  </footer>
);

export default Footer;
