import type { ComponentType } from 'react';
import { Home, Solution, Architecture, DataFlow, Component, Features, Roadmap, Services,Contact } from '../containers';

export type MetaInfoProps = Partial<
  Readonly<{
    meta: any[];
    lang: string;
    title: string;
    defer: boolean;
    locale: string;
    canonical_postfix: string;
    description: string;
    keywords: string[];
  }>
>;

export type Route = Readonly<{
  context: string,
  path: string;
  name: string;
  metaInfo: MetaInfoProps;
  Component: ComponentType;
}>;

export const routes: Route[] = [
{
    context: 'main',
    path: '/',
    name: 'Home',
    Component: Home,
    metaInfo: {
      canonical_postfix: '',
      title: 'The next generation regulatory reporting solution',
      description: `RegNxt is a super intuitive reporting solution, bringing together dataflows, management reporting and regulatory reporting in a single UI.`
    }
  },
  {
    context: 'main',
    path: '/solution',
    name: 'Solution',
    Component: Solution,
    metaInfo: {
      canonical_postfix: 'solution',
      title: 'RegNxt can operate in SaaS or on-premise modus.',
      description: `Wathever hosting is choosen, the platform is container based. This future-proof approach allows easy installation/upgrades.`
    }
    },
    {
        context: 'subsol',
        path: '/solution/architecture',
        name: 'Architecture',
        Component: Architecture,
        metaInfo: {
            canonical_postfix: 'solution/architecture',
            title: 'RegNxt has a simple yet modern architecture.',
            description: `The architecture allows various ways of working. All functionalities can be used via the UI or via an API (headless solution for integration into existing platform).`
        }
    },
    {
        context: 'subsol',
        path: '/solution/dataflow',
        name: 'DataFlow',
        Component: DataFlow,
        metaInfo: {
            canonical_postfix: 'solution/dataflow',
            title: 'RegNxt allows multiple dataflow scenarios.',
            description: `From simple datasets to fully fledged integration with the existing source systems.`
        }
    },
    {
        context: 'subsol',
        path: '/solution/component',
        name: 'Component',
        Component: Component,
        metaInfo: {
            canonical_postfix: 'solution/component',
            title: 'RegNxt functionalities are neatly grouped together in components.',
            description: `Clean desing of the components is reflected in the API, where functioanalities are grouped acordin to usage.`
        }
    },
    {
        context: 'subsol',
        path: '/solution/feature',
        name: 'Feature',
        Component: Features,
        metaInfo: {
            canonical_postfix: 'solution/feature',
            title: 'RegNxt is packed with useful features.',
            description: `Features allow collaboration, comparison and much more.`
        }
    },
    {
        context: 'subsol',
        path: '/solution/roadmap',
        name: 'Roadmap',
        Component: Roadmap,
        metaInfo: {
            canonical_postfix: 'solution/roadmap',
            title: 'RegNxt is based on a clear roadmap to further improve the user experience.',
            description: `The roadmap is revisited on regular basis and includes new demands from the market.`
        }
    },
    {
        context: 'main',
        path: '/services',
        name: 'Services',
        Component: Services,
        metaInfo: {
            canonical_postfix: 'services',
            title: 'RegNxt offers both technical and functional support to all reg related matters.',
            description: `We can help solve both technical and functional matters`,
            keywords: ['EBA', 'DPM', 'Regulatory Reporting']
        }
    },
    {
        context: 'main',
        path: '/contact',
        name: 'Contact',
        Component: Contact,
        metaInfo: {
            canonical_postfix: 'contact',
            title: 'Contact',
            description: `Contact RegNxt`
        }
    }
];

export const getRouteMetaInfo = (name: string): MetaInfoProps => {
  const route = routes.find((r) => r.name === name);
  return route?.metaInfo ?? {};
};

export const isLocationValidRoute = (pathname: string): boolean => {
  return routes.some((r) => r.path === pathname);
};