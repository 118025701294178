import React from 'react';
import type { FunctionComponent } from 'react';
import {  MetaInfo } from '../../components';
import { getRouteMetaInfo } from '../../config/routes.config';

const Services: FunctionComponent = () => (
  <div className="view-wrapper">
    <MetaInfo {...getRouteMetaInfo('Features')} />
        <section className="container">     
        <div className="container">
          <h1 className="title">Services</h1>
                RegNxt offers its customers 3 services:
                <div className="li-theme1">
                    <ul>
                        <li>A software solution for your regulatory reporting needs</li>
                        <li>Managed services: (partially) outsource the population/submission of reports</li>
                        <li>Consulting: implementing a fully fledged and automated platform to get the most out of it</li>
                    </ul>
                </div>
        </div>      
        </section>
  </div>
);

export default Services;
